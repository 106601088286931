import React from "react";
import { PageWrapper } from "../components";
import { InstagramOutlined, MailOutlined } from "@ant-design/icons";

export const Contact = () => {
  return (
    <PageWrapper className="!px-2">
      <h2 className="sm-max:pl-4 before:sm-max:ml-4 mt-0 relative before:content-[''] before:absolute before:-bottom-1 before:sm-max:-bottom-3 before:left-0 before:w-20 before:h-2 before:bg-mustard">
        Want to get in touch?
      </h2>
      <div className="flex flex-col gap-8">
        <div>
          <InstagramOutlined className="sm-max:mr-4 sm:mr-8" alt="Instagram" />
          @boostedbouillon
        </div>
        <div>
          <MailOutlined className="sm-max:mr-4 sm:mr-8" alt="Email" />
          <a
            href="mailto:boostedbouillon@gmail.com"
            className="text-inherit sm-max:text-2xl"
          >
            boostedbouillon@gmail.com
          </a>
        </div>
      </div>
    </PageWrapper>
  );
};
