import React from "react";

interface PI extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  role?: string;
  pronouns?: string;
  imgString: string;
}
export const PersonImg: React.FC<PI> = ({
  name,
  className,
  imgString,
  pronouns,
  role,
}) => {
  return (
    <div className="flex flex-col justify-center flex-grow">
      <img
        src={imgString}
        alt=""
        className="w-[360px] h-auto mx-auto border-4 border-dashed border-mustard rounded-2xl max-w-[95%] bg-white"
      />
      <p className="text-center my-4">
        {name} <span className="text-[75%]">({pronouns || "She/her"})</span>
      </p>
      <p className="text-center underline decoration-mustard mb-4 mt-0">
        {role || "Co-founder"}
      </p>
    </div>
  );
};
