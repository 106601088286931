import React from "react";
//@ts-ignore
import stewie from "../img/stewie.png";
//@ts-ignore
import { ReactComponent as Boosted } from "../img/boosted.svg";
import { PageWrapper } from "../components";

export const Landing = () => {
  return (
    <PageWrapper>
      <div className="my-0 text-center">Welcome to</div>
      <Boosted className="max-w-[95%]" />
      <img
        src={stewie}
        alt=""
        className="w-[75vw] h-[75vw] max-h-80 max-w-80"
      />
      <div className="my-0 text-center">We're Happy to Have You.</div>
    </PageWrapper>
  );
};
