import React from "react";
import { PageWrapper } from "../components";
//@ts-ignore
import Leah from "../img/Leah-Cropped.jpeg";
//@ts-ignore
import Sammy from "../img/Sammy-Cropped.jpeg";
//@ts-ignore
import Aleshia from "../img/Aleshia-Cropped.jpeg";
//@ts-ignore
import Stewie from "../img/stewie.png";
import { PersonImg } from "../sections";

export const About = () => {
  return (
    <PageWrapper className="!px-6 sm-max:gap-16 sm:gap-32">
      <div className="border-dashed border-mustard border-8 rounded-2xl p-10 sm-max:px-4 max-w-screen-lg mt-16 sm-max:mt-6">
        <h2 className="mt-0 relative before:content-[''] before:absolute before:-bottom-1 before:sm-max:-bottom-3 before:left-0 before:w-20 before:h-2 before:bg-mustard">
          Who Are We?
        </h2>
        <p className="text-[75%]">
          BOOSTED! is a nutritional bouillon company based out of Guelph,
          Ontario. We are committed to revolutionizing home cooking by making
          nutritious meals convenient, empowering you to create wholesome dishes
          that your family will love without spending hours in the kitchen.
        </p>
      </div>
      <div className="m-10 sm-max:mx-6 max-w-full w-full">
        <h2 className="mt-0 relative before:content-[''] before:absolute before:-bottom-1 before:sm-max:-bottom-3 before:left-0 before:w-20 before:h-2 before:bg-mustard w-fit mx-auto">
          Meet the Team!
        </h2>
        <p className="text-[75%] max-w-screen-lg mx-auto text-center">
          Our team is composed of three dedicated Master's of Science students
          in Human Health and Nutritional Science at the University of Guelph,
          and one amazing mascot!
        </p>
        <div className="flex flex-wrap gap-6 w-full">
          <PersonImg name="Aleshia" imgString={Aleshia} />
          <PersonImg name="Leah" imgString={Leah} />
          <PersonImg name="Sammy" imgString={Sammy} />
          <PersonImg
            name="Stewie"
            imgString={Stewie}
            pronouns="He/Him"
            role="Mascot"
          />
        </div>
      </div>
    </PageWrapper>
  );
};
