import React, { useState } from "react";
import { NavItem } from "../components";
import { ROUTES } from "../routes";
//@ts-ignore
import { ReactComponent as Logo } from "../img/boosted.svg";
import { MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const Nav = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className="w-full h-20 bg-olive flex items-center justify-between relative font-grenoble flex-shrink-0">
      <Logo
        className="max-h-full mt-1 w-60 cursor-pointer"
        onClick={() => {
          navigate(ROUTES.root);
          setOpen(false);
        }}
      />
      {/* Desktop View */}
      <div className="hidden gap-12 mr-6 md:flex text-cream font-semibold text-lg">
        <Items />
      </div>

      {/* Mobile View */}
      <MenuOutlined
        className="md:hidden mr-4 text-[150%] text-cream cursor-pointer"
        onClick={() => setOpen(!open)}
      />
      {open && (
        <>
          <div
            className="absolute top-20 left-0 h-[calc(100vh-80px)] w-full bg-black/75"
            onClick={() => setOpen(false)}
          />
          <div className="absolute bg-olive right-0 top-20 py-10 w-full flex flex-col gap-8 items-end shadow-md z-10 md-max:text-cream">
            <Items
              dispatch={() => {
                setOpen(false);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

interface IItems extends React.HTMLAttributes<HTMLDivElement> {
  dispatch?: () => void;
}
const Items: React.FC<IItems> = ({ dispatch }) => {
  return (
    <>
      <NavItem
        name="About Us"
        active={false}
        link={ROUTES.about}
        className=" mx-auto"
        dispatch={dispatch}
      />
      <NavItem
        name="Product & Pricing"
        active={false}
        link={ROUTES.product}
        className="col-span-2 mx-auto"
        dispatch={dispatch}
      />
      <NavItem
        name="Contact Us"
        active={false}
        link={ROUTES.contact}
        className="col-span-2 mx-auto"
        dispatch={dispatch}
      />
    </>
  );
};
