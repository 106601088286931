import React from "react";
import { PageWrapper } from "../components";
//@ts-ignore
import sadStewie from "../img/sad-stewie.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <PageWrapper className="sm-max:px-4">
      <img src={sadStewie} alt="" className="max-h-96 max-w-[80%] h-auto" />
      <h2 className="my-0 mb-8 mt-2">Page not found!</h2>
      <div
        className="bg-olive text-white rounded-xl p-4 text-2xl tracking-wide font-normal hover:cursor-pointer hover:scale-[102%]"
        onClick={() => navigate(ROUTES.root)}
      >
        Return Home
      </div>
    </PageWrapper>
  );
};
