import React from "react";

export const PageWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => {
  return (
    <div
      className={
        "flex-grow flex flex-col justify-center items-center font-black text-3xl sm:text-5xl text-green px-8 font-grenoble " +
          className || ""
      }
    >
      {children}
    </div>
  );
};
